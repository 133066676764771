.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.flowLeftFast {stroke-dasharray: 5; animation: dashdrawLeftFast 5s linear infinite;}
@keyframes dashdrawLeftFast {to {stroke-dashoffset: 200;}}

.flowRightFast {stroke-dasharray: 5; animation: dashdrawRightFast 5s linear infinite;}
@keyframes dashdrawRightFast {to {stroke-dashoffset: -200;}}

.flowLeft {stroke-dasharray: 5; animation: dashdrawLeft 5s linear infinite;}
@keyframes dashdrawLeft {
	10%, 20% {stroke-dashoffset: 40;}
	30%, 40% {stroke-dashoffset: 80;}
	50%, 60% {stroke-dashoffset: 120;}
	70%, 80% {stroke-dashoffset: 160;}
	90%, 100% {stroke-dashoffset: 200;}	
	}

.flowRight {stroke-dasharray: 5; animation: dashdrawRight 5s linear infinite;}
@keyframes dashdrawRight {
	10%, 20% {stroke-dashoffset: -40;}
	30%, 40% {stroke-dashoffset: -80;}
	50%, 60% {stroke-dashoffset: -120;}
	70%, 80% {stroke-dashoffset: -160;}
	90%, 100% {stroke-dashoffset: -200;}	
	}

@-webkit-keyframes rotating-left /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(-360deg);
    -o-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}
@keyframes rotating-left {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(-360deg);
    -moz-transform: rotate(-360deg);
    -webkit-transform: rotate(-360deg);
    -o-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}
.rotating-left {
  -webkit-animation: rotating-left 2s linear infinite;
  -moz-animation: rotating-left 2s linear infinite;
  -ms-animation: rotating-left 2s linear infinite;
  -o-animation: rotating-left 2s linear infinite;
  animation: rotating-left 2s linear infinite;
}

@-webkit-keyframes rotating-right /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotating-right {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.rotating-right {
  -webkit-animation: rotating-right 2s linear infinite;
  -moz-animation: rotating-right 2s linear infinite;
  -ms-animation: rotating-right 2s linear infinite;
  -o-animation: rotating-right 2s linear infinite;
  animation: rotating-right 2s linear infinite;
}

circle{
  pointer-events: auto;
}

.shadow {
  -webkit-filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, .7));
  filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, .7));
  /* Similar syntax to box-shadow */
}

.box {
  padding: 0px;
  box-shadow: -2px 2px 5px #000;
}

.handle, .section_header {
  background-color: #3a383b ;
  padding: 5px;
}

.no-drag {
  user-drag: none; 
  -moz-user-select: none;
  -webkit-user-drag: none;
}

.divgrid {
  background: #272227;    
  background-image: url(grid.png);
  background-attachment: local;
}

.divnogrid {
  background: #272227;    
}

